export const TableData=[
    {date:'7 Nov, 2023',time:'14:00',amount:'NGN 34,000.00', id:'51346789566666', branch:'Omole Phase 1', type:'CARD',status:'Successful'},
    {date:'8 Nov, 2023',time:'15:00',amount:'NGN 34,000.00', id:'51346789566666', branch:'Omole Phase 1', type:'CARD',status:'Successful'},
    {date:'6 Nov, 2023',time:'14:00',amount:'NGN 34,000.00', id:'51346789566666', branch:'Omole Phase 1', type:'CARD',status:'Successful'},
    {date:'7 Nov, 2023',time:'14:00',amount:'NGN 34,000.00', id:'51346789566666', branch:'Omole Phase 1', type:'CARD',status:'Successful'},
    {date:'7 Nov, 2023',time:'14:00',amount:'NGN 34,000.00', id:'51346789566666', branch:'Omole Phase 1', type:'CARD',status:'Successful'},
    {date:'7 Nov, 2023',time:'14:00',amount:'NGN 34,000.00', id:'51346789566666', branch:'Omole Phase 1', type:'CARD',status:'Successful'},
    {date:'7 Nov, 2023',time:'14:00',amount:'NGN 34,000.00', id:'51346789566666', branch:'Omole Phase 1', type:'CARD',status:'Successful'},
    {date:'7 Nov, 2023',time:'14:00',amount:'NGN 34,000.00', id:'51346789566666', branch:'Omole Phase 1', type:'CARD',status:'Successful'},
    {date:'7 Nov, 2023',time:'14:00',amount:'NGN 34,000.00', id:'51346789566666', branch:'Omole Phase 1', type:'CARD',status:'Successful'},
    {date:'7 Nov, 2023',time:'14:00',amount:'NGN 34,000.00', id:'51346789566666', branch:'Omole Phase 1', type:'CARD',status:'Successful'},
]

export const headers=['Date','Amount','Transaction ID',
// 'Branch or Name',
'Transaction Type','Status']